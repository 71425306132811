import React, {useEffect} from 'react';
import {Modal} from "react-bootstrap";
import {TerminalInfoModalHeader} from "./ModalHeader";
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {fetchTerminalInfo} from "../../../../redux/licenses/licensesActions";
import TerminalInfoModalContent from "./ModalContent";

function TerminalInfoModal({onHide, show, terminalStockId}) {
  const dispatch = useDispatch()
  const {terminalInfo, actionsLoading} = useSelector(
    (state) => ({
      terminalInfo: state.licenses.terminalInfo,
      actionsLoading: state.licenses.actionsLoading
    }),
    shallowEqual
  );

  useEffect(() => {
    terminalStockId && dispatch(fetchTerminalInfo(terminalStockId))
  }, [terminalStockId])
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
    >
      <TerminalInfoModalHeader/>
      <Modal.Body className="overlay overlay-block cursor-default">
        {
          actionsLoading && <div className="spinner spinner-track spinner-right spinner-primary spinner-lg m-auto"></div>
        }
        {
          !actionsLoading && <TerminalInfoModalContent terminalInfo={terminalInfo}/>
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

TerminalInfoModal.propTypes = {};
TerminalInfoModal.defaultProps = {};

export default TerminalInfoModal;
