import * as companyApi from "../../app/apis/companyApi";
import {bankCardsSlice, callTypes} from "./bankCardsSlice";
import {toast} from "react-toastify";
const {actions} = bankCardsSlice;

export const fetchBankCards = (queryParams) => dispatch => {
  const searchText = queryParams && queryParams.filter ? queryParams.filter.searchText : '';
  dispatch(actions.startCall({ callType: callTypes.list }));
  return companyApi
    .findBankCards(searchText)
    .then(response => {
      dispatch(actions.bankCardsFetched({
        totalCount: response.length,
        entities: response
      }));
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateBankCard = formData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return companyApi
    .updateBankAccountNumber(formData)
    .then(response => {
      toast.success("Update Bank Card Successfully!");
      return response;
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false
    });
};
