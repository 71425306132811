import React, {useEffect, useMemo} from 'react';
import {isEqual} from "lodash";
import {Controller, useForm} from 'react-hook-form';
import {Button, Spinner} from 'react-bootstrap'


import {useLicensesUIContext} from "../LicensesUIContext";
import useLicensesSelector from "../hooks/useLicensesSelector";
import SelectResellers from "../../../containers/SelectRessellers";

const prepareFilter = (queryParams, values) => {
  const newQueryParams = {...queryParams};
  newQueryParams.filter = {
    ...values
  };
  return newQueryParams;
};

function LicensesFilter() {
  // Customers UI Context
  const licensesUIContext = useLicensesUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: licensesUIContext.queryParams,
      setQueryParams: licensesUIContext.setQueryParams,
    };
  }, [licensesUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter({
      ...customersUIProps.queryParams
    }, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const {register, handleSubmit, reset, control} = useForm();

  const handleResetSearch = () => {
    reset();
    handleSubmit()
  }
  const {totalCount, listLoading} = useLicensesSelector() || {};
  return (
    <form onSubmit={handleSubmit(applyFilter)} className="form form-label-right">
      <div className="font-weight-bold" style={{textAlign: 'right'}}>
        <h5>Total: {totalCount}</h5>
      </div>
      <br />
      <div className="form-group row">
        <div className="col-lg-2">
          <select
            className="form-control"
            name="freeStatus"
            ref={register}
            placeholder="Licences Status"
          >
            <option value="all">All</option>
            <option value="free">Free</option>
            <option value="used">Used</option>
          </select>
          <small className="form-text text-muted">
            <b>Filter</b> by Licences Status
          </small>
        </div>
        <div className="col-lg-2">
          <select
            className="form-control"
            name="activeStatus"
            ref={register}
            placeholder="Active Status"
          >
            <option value="all">All</option>
            <option value="active">Active</option>
            <option value="blocked">Blocked</option>
          </select>
          <small className="form-text text-muted">
            <b>Filter</b> by Active Status
          </small>
        </div>
        <div className="col-lg-2">
          <Controller
            control={control}
            defaultValue={null}
            name="resellerId"
            as={SelectResellers}
          />
          <small className="form-text text-muted">
            <b>Filter</b> by Reseller
          </small>
        </div>
        <div className="col-lg-3">
          <input
            type="text"
            ref={register}
            className="form-control"
            name="searchText"
            placeholder="Search"
          />
          <small className="form-text text-muted">
            <b>Search</b> in all fields
          </small>
        </div>
        <div className="col-lg-3">
          {
            listLoading && <Button variant="success" className={"mr-3"} disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Searching...
            </Button>
          }
          {
            !listLoading && <button
              className="btn btn-success mr-3"
            >
              Search
            </button>
          }
          <button
            onClick={handleResetSearch}
            className="btn btn-warning"
          >
            Reset
          </button>
        </div>
      </div>
    </form>
  );
}

LicensesFilter.propTypes = {};
LicensesFilter.defaultProps = {};

export default LicensesFilter;
