import React, {useEffect} from 'react';
import {Alert, Button, Modal, Spinner} from "react-bootstrap";
import {GenerateLicenseModalHeader} from "./ModalHeader";
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {useForm, Controller} from "react-hook-form";
import SelectResellers from "../../../containers/SelectRessellers";
import {fetchLicenses, generateLicenses} from "../../../../redux/licenses/licensesActions";
import {useLicensesUIContext} from "../LicensesUIContext";
import GeneratedLicenseList from "./GeneratedLicenseList";
import {LICENSE_TYPE} from "../../../constants";
import {licensesSlice} from "../../../../redux/licenses/licensesSlice";

const {actions} = licensesSlice;

function GenerateLicenseModal({onHide, show}) {
  const dispatch = useDispatch()
  const {initGenerateLicensesValues, queryParams, setActiveTabKey} = useLicensesUIContext() || {};
  const {handleSubmit, reset, errors, control} = useForm();

  const {actionsLoading: loading, generatedLicenses, error} = useSelector(
    (state) => ({
      actionsLoading: state.licenses.actionsLoading,
      error: state.licenses.error,
      generatedLicenses: state.licenses.generatedLicenses
    }),
    shallowEqual
  );

  const onSubmit = (formValues) => {
    dispatch(generateLicenses(formValues)).then(rs => {
      if (rs) {
        reset(initGenerateLicensesValues)
        setActiveTabKey(LICENSE_TYPE.API)
        dispatch(fetchLicenses({
          ...queryParams,
          filter: {
            ...queryParams.filter,
            type: "2"
          },
        }))
        onHide();
      }
    })
  }
  console.log({
    queryParams
  })
  React.useEffect(() => {
    return () => dispatch(actions.resetState())
  }, [])

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="form form-label-right">
        <GenerateLicenseModalHeader/>
        <Modal.Body className="overlay overlay-block cursor-default">
          {
            error && <Alert variant="danger" dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>
                Please contact administrator!
              </p>
            </Alert>
          }
          <div className="form-group row">
            <div className="col-lg-6">
              <small className="form-text text-muted">
                <b>Select Reseller</b>
              </small>
              <Controller
                control={control}
                defaultValue={initGenerateLicensesValues.resselerId}
                rules={{required: true}}
                name="resselerId"
                as={SelectResellers}
              />
              {
                errors.resselerId && <div className={"invalid-feedback"} style={{display: 'block'}}>
                  Please choose a reseller.
                </div>
              }
            </div>
            <div className="col-lg-3">
              <small className="form-text text-muted">
                <b>Total</b>
              </small>
              <Controller
                control={control}
                name="total"
                defaultValue={initGenerateLicensesValues.total}
                rules={{required: true, min: 1}}
                as={<input placeholder="Total" type="number" className="form-control"/>}
              />
              {
                errors.total && <div className={"invalid-feedback"} style={{display: 'block'}}>
                  Please input valid Total
                </div>
              }
            </div>
          </div>
          {generatedLicenses && generatedLicenses.length && <GeneratedLicenseList licenseItems={generatedLicenses}/>}
        </Modal.Body>
        <Modal.Footer>
          {
            loading && <Button variant="primary" className={"mr-3"} disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          }
          {
            !loading && <React.Fragment>
              <Button variant="secondary" onClick={onHide}>No</Button>
              <Button variant="primary" type={'submit'}>Yes</Button>
            </React.Fragment>
          }
        </Modal.Footer>
      </form>
    </Modal>
  );
}

GenerateLicenseModal.propTypes = {};
GenerateLicenseModal.defaultProps = {};

export default GenerateLicenseModal;
