import React from 'react';
import {
  getReseller3rdLicense, fetchReseller3rdLicenses, setEditingRes3rdItemId,
  updateReseller3rdLicense
} from "../store/licenses-reseller3rd/licensesReseller3rdActions";
import {
  useOpenRes3rdEditIdSelector,
  useRes3rdActionsLoadingSelector,
  useRes3rdEditItemSelector
} from "../hooks/useReseller3rdSystemSelectors";
import {useDispatch} from "react-redux";
import {Alert, Button, Modal, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import SelectResellers from "../../../../containers/SelectRessellers";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";

export function useRes3rdSystemForm() {
  const res3rdEditId = useOpenRes3rdEditIdSelector();
  const res3rdEditItem = useRes3rdEditItemSelector() || {};
  const formDefaultValues = {
    resellerId: null,
    name: "",
    freeTag1: "",
    freeTag2: "",
    freeTag3: ""
  }

  const actionsLoading = useRes3rdActionsLoadingSelector();
  const {handleSubmit, reset, errors, control} = useForm();

  const onHide = () => {
    dispatch(setEditingRes3rdItemId(undefined))
  }

  const onSubmit = (formValues) => {
    if (res3rdEditId) {
      dispatch(updateReseller3rdLicense(res3rdEditId, {...formValues, isActive: true})).then(rs => {
        if (rs) {
          // reset(initGenerateLicensesValues)
          dispatch(fetchReseller3rdLicenses())
          onHide();
        }
      })
    }
  }
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (res3rdEditId) {
      dispatch(getReseller3rdLicense(res3rdEditId))
    }
  }, [res3rdEditId])

  React.useEffect(() => {
    if (res3rdEditItem) {
      reset({
          resellerId: res3rdEditItem.resellerId,
          name: res3rdEditItem.name,
          freeTag1: res3rdEditItem.freeTag1,
          freeTag2: res3rdEditItem.freeTag2,
          freeTag3: res3rdEditItem.freeTag3,
        }
      )
    }
  }, [res3rdEditItem])

  return {
    res3rdEditItem,
    actionsLoading,
    onHide,
    onSubmit,
    defaultValues: formDefaultValues,
    formProps: {
      errors,
      control,
      handleSubmit
    }
  }

}

function Reseller3rdSystemForm(props) {
  const {
    res3rdEditItem, actionsLoading, onSubmit, defaultValues, onHide,
    formProps: {errors, handleSubmit, control}
  } = useRes3rdSystemForm();

  console.log({
    res3rdEditItem
  })
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form form-label-right">
      <>
        {actionsLoading && <ModalProgressBar/>}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Edit Reseller 3rd License</Modal.Title>
        </Modal.Header>
      </>
      <Modal.Body className="overlay overlay-block cursor-default">
        <div className="form-group row">
          <div className="col-lg-6">
            <small className="form-text text-muted">
              <b>Name</b>
            </small>
            <Controller
              control={control}
              name="name"
              defaultValue={defaultValues.name}
              rules={{required: true}}
              as={<input type="text" className="form-control"/>}
            />
            {
              errors.name && <div className={"invalid-feedback"} style={{display: 'block'}}>
                Please input valid Name
              </div>
            }
          </div>
          <div className="col-lg-6">
            <small className="form-text text-muted">
              <b>Select Reseller</b>
            </small>
            <Controller
              control={control}
              defaultValue={defaultValues.resellerId}
              rules={{required: true}}
              name="resellerId"
              disabled
              as={SelectResellers}
            />
            {
              errors.resselerId && <div className={"invalid-feedback"} style={{display: 'block'}}>
                Please choose a reseller.
              </div>
            }
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-4">
            <small className="form-text text-muted">
              <b>Free Tag 1</b>
            </small>
            <Controller
              control={control}
              name="freeTag1"
              defaultValue={defaultValues.freeTag1}
              as={<input type="text" className="form-control"/>}
            />
          </div>
          <div className="col-lg-4">
            <small className="form-text text-muted">
              <b>Free Tag 2</b>
            </small>
            <Controller
              control={control}
              name="freeTag2"
              defaultValue={defaultValues.freeTag2}
              as={<input type="text" className="form-control"/>}
            />
          </div>
          <div className="col-lg-4">
            <small className="form-text text-muted">
              <b>Free Tag 3</b>
            </small>
            <Controller
              control={control}
              name="freeTag3"
              defaultValue={defaultValues.freeTag3}
              as={<input type="text" className="form-control"/>}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {
          actionsLoading && <Button variant="primary" className={"mr-3"} disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        }
        {
          !actionsLoading && <React.Fragment>
            <Button variant="secondary" onClick={onHide}>No</Button>
            <Button variant="primary" type={'submit'}>Yes</Button>
          </React.Fragment>
        }
      </Modal.Footer>
    </form>
  );
}

Reseller3rdSystemForm.propTypes = {};
Reseller3rdSystemForm.defaultProps = {};

export default Reseller3rdSystemForm;
