import React, {useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider,} from "react-bootstrap-table2-paginator";
import {format} from 'date-fns'

import {getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage,} from "../../../../_metronic/_helpers";

import {Pagination} from "../../../../_metronic/_partials/controls";

import {useLicensesUIContext} from "../LicensesUIContext";
import {useDispatch} from "react-redux";
import * as uiHelpers from "../LicensesUIHelpers";
import * as actions from "../../../../redux/licenses/licensesActions";
import useLicensesSelector from "../hooks/useLicensesSelector";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import YesNoLabel from "../../../components/YesNoLabel";
import {LICENSE_TYPE} from "../../../constants";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openTerminalInfoModal,
    openResetLicenseModal,
    openActivateLicenseModal,
  }
) {
  return (
    <>

      {
        row.terminalStockLinked && <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip>
              View Terminal Info
            </Tooltip>
          }
        >
          <a
            title="View Terminal Info"
            className="btn btn-icon btn-primary btn-sm mx-3"
            onClick={() => openTerminalInfoModal(row.terminalStockId)}
          >
            <i className={"fa fa-eye"}></i>
          </a>
        </OverlayTrigger>
      }
      <> </>

      <> </>
      {
        row.isRegistered && row.isActive && <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip>
              Reset Serial
            </Tooltip>
          }
        >
          <a
            title="Reset Serial"
            className="btn btn-icon btn-danger btn-sm"
            onClick={() => openResetLicenseModal(window.btoa(JSON.stringify(row)))}
          >
            <i className="fa fa-undo"></i>
          </a>
        </OverlayTrigger>
      }
      {
        !row.isActive && <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip>
              Active Serial
            </Tooltip>
          }
        >
          <a
            title="Active Serial"
            className="btn btn-icon btn-success btn-sm"
            onClick={() => openActivateLicenseModal(window.btoa(JSON.stringify(row)))}
          >
            <i className="fa fa-check"></i>
          </a>
        </OverlayTrigger>
      }

    </>
  );
}


function LicensesResults({type}) {
  const licensesUIContext = useLicensesUIContext();
  const licensesUIProps = useMemo(() => {
    return {
      queryParams: licensesUIContext.queryParams,
      activeTabKey: licensesUIContext.activeTabKey,
      setQueryParams: licensesUIContext.setQueryParams,
      openTerminalInfoModal: licensesUIContext.openTerminalInfoModal,
      openResetLicenseModal: licensesUIContext.openResetLicenseModal,
      openActivateLicenseModal: licensesUIContext.openActivateLicenseModal
    };
  }, [licensesUIContext]);


  const {totalCount, entities, listLoading} = useLicensesSelector() || {};
  const dispatch = useDispatch();
  useEffect(() => {
    if(type.toString() === licensesUIProps.activeTabKey.toString()) {
      dispatch(actions.fetchLicenses({
        ...licensesUIProps.queryParams,
        filter: {
          ...licensesUIProps.queryParams.filter,
          type: licensesUIProps.activeTabKey
        }
      }))
    }
  }, [licensesUIProps.queryParams, dispatch, licensesUIProps.activeTabKey]);

  const apiCols = [
    {
      text: "Terminal",
      dataField: "terminal",
    },
    {
      text: 'POS ID',
      dataField: "serial",
    },
    {
      text: "Active",
      dataField: "isActive",
      formatter: (isActive) => {
        return <YesNoLabel value={isActive}/>
      },
    }
  ];

  const appCols = [
    {
      text: "Terminal",
      dataField: "terminal",
    },
    {
      text: "Serial",
      dataField: "serial",
    },
    {
      text: "Active",
      dataField: "isActive",
      formatter: (isActive) => {
        return <YesNoLabel value={isActive}/>
      },
    }, {
      text: "Registered",
      dataField: "isRegistered",
      formatter: (isActive) => {
        return <YesNoLabel value={isActive}/>
      },
    },
    {
      text: "Registered Date",
      dataField: "dregistered",
      formatter: (registeredDate) => {
        const dateValue = registeredDate ? format(new Date(registeredDate), 'dd-MM-yyyy HH:mm') : '';
        return <span>{dateValue}</span>
      },
    },
  ];

  const columns = [
    {
      text: "action",
      dataField: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openTerminalInfoModal: licensesUIProps.openTerminalInfoModal,
        openResetLicenseModal: licensesUIProps.openResetLicenseModal,
        openActivateLicenseModal: licensesUIProps.openActivateLicenseModal,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: licensesUIProps.queryParams.pageSize,
    page: licensesUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="serial"
                data={entities === null ? [] : entities}
                columns={ type === LICENSE_TYPE.APP ? [...appCols, ...columns] : [...apiCols, ...columns]}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  licensesUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities}/>
                <NoRecordsFoundMessage entities={entities}/>
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

LicensesResults.propTypes = {};
LicensesResults.defaultProps = {};

export default LicensesResults;
