import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";

export default function useBankCardsSelector() {
  const { currentState } = useSelector(
    (state) => ({ currentState: state.bankCards }),
    shallowEqual
  );
  return currentState
}
