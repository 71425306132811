import React from 'react';
import PropTypes from 'prop-types';
import Reseller3rdSystemList from "./components/Reseller3rdSystemList";
import Reseller3rdSystemModal from "./components/Reseller3rdSystemModal";

function Reseller3rdSystem(props) {
  return (
    <div>
      <Reseller3rdSystemList />
      <Reseller3rdSystemModal />
    </div>
  );
}

Reseller3rdSystem.propTypes = {};
Reseller3rdSystem.defaultProps = {};

export default Reseller3rdSystem;
