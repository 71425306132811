import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";

function TerminalInfoModalContent({terminalInfo = {}}) {
  const {companyName, shopName, terminalLogin, terminalPassword} = terminalInfo || {};
  return (
    <div className="card card-custom">
      <div className="card-body py-4">

        <div className="form-group row my-2">
          <label className="col-4 col-form-label">Company:</label>
          <div className="col-8">
            <span className="form-control-plaintext font-weight-bolder">{companyName}</span>
          </div>
        </div>

        <div className="form-group row my-2">
          <label className="col-4 col-form-label">Shop:</label>
          <div className="col-8">
            <span className="form-control-plaintext font-weight-bolder">{shopName}</span>
          </div>
        </div>

        <div className="form-group row my-2">
          <label className="col-4 col-form-label">Terminal Login:</label>
          <div className="col-8">
            <span className="form-control-plaintext font-weight-bolder">{terminalLogin}</span>
          </div>
        </div>

        <div className="form-group row my-2">
          <label className="col-4 col-form-label">Terminal Password:</label>
          <div className="col-8">
            <span className="form-control-plaintext font-weight-bolder">{terminalPassword}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

TerminalInfoModalContent.propTypes = {};
TerminalInfoModalContent.defaultProps = {};

export default TerminalInfoModalContent;
