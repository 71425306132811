import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Spinner, Alert} from "react-bootstrap";

function ConfirmModal({show, onHide, onOk, title = 'Confirm', content, loading, error}) {
  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/*{*/}
        {/*  error && <Alert variant="danger" dismissible>*/}
        {/*    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>*/}
        {/*    <p>*/}
        {/*      Please contact administrator!*/}
        {/*    </p>*/}
        {/*  </Alert>*/}
        {/*}*/}
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        {
          loading && <Button variant="primary" className={"mr-3"} disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        }
        {
          !loading && <React.Fragment>
            <Button variant="secondary" onClick={onHide}>No</Button>
            <Button variant="primary" onClick={onOk}>Yes</Button>
          </React.Fragment>
        }

      </Modal.Footer>
    </Modal>
  );
}

ConfirmModal.propTypes = {};
ConfirmModal.defaultProps = {};

export default ConfirmModal;
