import {useDispatch} from "react-redux";
import {useLicensesUIContext} from "../../LicensesUIContext";
import React from "react";
import * as licensesReseller3rdActions from "../store/licenses-reseller3rd/licensesReseller3rdActions";
import {useReseller3rdSystemStateSelector} from "./useReseller3rdSystemSelectors";
import {setEditingRes3rdItemId} from "../store/licenses-reseller3rd/licensesReseller3rdActions";
import ActionsColumnFormatter from "../components/ActionsColumnFormatter";

function use3rdTableCols(onEdit) {
  return React.useMemo(() => {
    return [
      {
        text: "Name",
        dataField: "name",
      },
      {
        text: "Reseller Name",
        dataField: "resellerName",
      },
      {
        text: 'Auth Token',
        dataField: "authToken",
      },
      {
        text: "Free Tag 1",
        dataField: "freeTag1",
      },
      {
        text: "Free Tag 2",
        dataField: "freeTag2",
      },
      {
        text: "Free Tag 3",
        dataField: "freeTag3",
      },
      {
        text: "action",
        dataField: "Actions",
        formatter: (cellContent, row) => {
          const handleEdit = () => {
            onEdit(row)
          }
          return <ActionsColumnFormatter onEdit={handleEdit} cellContent={cellContent} />
        },
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
          minWidth: "100px",
        },
      },
    ];
  }, [onEdit])
}


export function useReseller3rdLicenses() {
  const dispatch = useDispatch();
  const licensesUIContext = useLicensesUIContext();
  const reseller3rdSystemState = useReseller3rdSystemStateSelector();

  const onEdit = (rowData) => {
    dispatch(setEditingRes3rdItemId(rowData.id))
  }
  const tableCols = use3rdTableCols(onEdit);
  React.useEffect(() => {
    if ("reseller-3rd" === licensesUIContext.activeTabKey) {
      dispatch(licensesReseller3rdActions.fetchReseller3rdLicenses())
    }
    return () => dispatch(licensesReseller3rdActions.resetReseller3rdLicenseState())
  }, [dispatch, licensesUIContext.activeTabKey])
  return {
    totalCount: reseller3rdSystemState.totalCount,
    entities: reseller3rdSystemState.licenseReseller3rdItems,
    loading: reseller3rdSystemState.listLoading,
    tableCols
  }
}
