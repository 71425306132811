import React from 'react';
import PropTypes from 'prop-types';

function YesNoLabel({value}) {
  return (
    value ? <span className="badge badge-success">Yes</span> :
      <span className="badge badge-secondary">No</span>
  );
}

YesNoLabel.propTypes = {};
YesNoLabel.defaultProps = {};

export default YesNoLabel;
