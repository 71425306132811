import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {findAllResellers} from "../../apis/licenseApi";

function SelectResellers(props) {
  const [resellers, setResellers] = useState([]);
  useEffect(() => {
    findAllResellers().then(rs => {
      setResellers(rs);
    })
    return () => {
      setResellers([])
    };
  }, []);

  return (
    <select
      className="form-control"
      name="resselerId"
      placeholder="Select Reseller"
      {...props}
    >
      <option value={undefined}>Select Reseller</option>
      {
        resellers.map(item => {
          return <option key={item.nid} value={item.nid}>{item.sname}</option>
        })
      }
    </select>
  );
}

export default SelectResellers;
