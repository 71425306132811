import React from 'react';
import Reseller3rdSystemForm from "./Reseller3rdSystemForm";
import {Modal} from "react-bootstrap";
import {useOpenRes3rdEditIdSelector} from "../hooks/useReseller3rdSystemSelectors";
import {useDispatch} from "react-redux";
import {setEditingRes3rdItemId} from "../store/licenses-reseller3rd/licensesReseller3rdActions";

export function useReseller3rdSystemModal() {
  const res3rdEditId = useOpenRes3rdEditIdSelector();
  const show = !!res3rdEditId;
  const dispatch = useDispatch();
  const onHide = () => {
    dispatch(setEditingRes3rdItemId(undefined))
  };

  return {
    show,
    onHide
  }
}

function Reseller3rdSystemModal() {
  const {
    show,
    onHide
  } = useReseller3rdSystemModal();
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
    >
      <Reseller3rdSystemForm/>
    </Modal>
  );
}

Reseller3rdSystemModal.propTypes = {};
Reseller3rdSystemModal.defaultProps = {};

export default Reseller3rdSystemModal;
