/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
          }
        >
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {user.username}
          </span>
        </div>
        <Link
          to="/logout"
          className="btn btn-light-primary font-weight-bold"
        >
          Sign Out
        </Link>
      </Dropdown.Toggle>
      {/*<Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">*/}
      {/*  <>*/}
      {/*    /!** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' *!/*/}

      {/*    {!layoutProps.light && (*/}
      {/*      <div*/}
      {/*        className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"*/}
      {/*        style={{*/}
      {/*          backgroundImage: `url(${toAbsoluteUrl(*/}
      {/*            "/media/misc/bg-1.jpg"*/}
      {/*          )})`,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <div className="symbol bg-white-o-15 mr-3">*/}
      {/*          <span className="symbol-label text-success font-weight-bold font-size-h4">*/}
      {/*            {user.firstname[0]}*/}
      {/*          </span>*/}
      {/*          /!*<img alt="Pic" className="hidden" src={user.pic} />*!/*/}
      {/*        </div>*/}
      {/*        <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">*/}
      {/*          {user.username}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </>*/}

      {/*  <div className="navi navi-spacer-x-0 pt-5">*/}
      {/*    <div className="navi-footer  px-8 py-5">*/}
      {/*      <Link*/}
      {/*        to="/logout"*/}
      {/*        className="btn btn-light-primary font-weight-bold"*/}
      {/*      >*/}
      {/*        Sign Out*/}
      {/*      </Link>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Dropdown.Menu>*/}
    </Dropdown>
  );
}
