import React from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";

import LicensesFilter from "./Filter";
import LicensesResults from "./Results";
import {useLicensesUIContext} from "../LicensesUIContext";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {LICENSE_TYPE} from "../../../constants";
import Reseller3rdSystem from "../Reseller3rdSystem";

function LicensesList() {
  const {setActiveTabKey, activeTabKey, openGenerateLicenseModal} = useLicensesUIContext();
  const handleTabChange = (eventKey) => {
    setActiveTabKey(eventKey);
  }
  return (
    <Card>
      <CardHeader title="License List">
        {
          activeTabKey == LICENSE_TYPE.API &&  <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={openGenerateLicenseModal}
            >
              New API License
            </button>
          </CardHeaderToolbar>
        }
      </CardHeader>
      <CardBody>
          <Tabs defaultActiveKey={LICENSE_TYPE.APP} activeKey={activeTabKey} onSelect={handleTabChange}>
            <Tab eventKey={LICENSE_TYPE.APP} title="App Licenses">
              <div className={"mt-5"}>
                <LicensesFilter/>
                <LicensesResults type={LICENSE_TYPE.APP}/>
              </div>
            </Tab>
            <Tab eventKey={LICENSE_TYPE.API} title="API Licenses">
              <div className={"mt-5"}>
                <LicensesFilter/>
                <LicensesResults type={LICENSE_TYPE.API}/>
              </div>
            </Tab>

              <Tab eventKey={"reseller-3rd"} title="Reseller 3rd">
              <div className={"mt-5"}>
                <Reseller3rdSystem />
              </div>
            </Tab>
          </Tabs>
      </CardBody>
    </Card>
  );
}

LicensesList.propTypes = {};
LicensesList.defaultProps = {};

export default LicensesList;
