import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";

export default function useLicensesSelector() {
  const { currentState } = useSelector(
    (state) => ({ currentState: state.licenses }),
    shallowEqual
  );
  return currentState
}
