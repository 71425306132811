import React from 'react';
import {Route} from "react-router-dom";
import BankCardsList from "./List";
import {useSubheader} from "../../../_metronic/layout";
import {BankCardsUIProvider} from "./BankCardsUIContext";
import UpdateBankCardsModal from "./UpdateBankCardsModal";

function BankCardsPage({history}) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Bank Cards");
  const bankCardsUIEvents = {
    openUpdateBankCardModal: (id) => {
      history.push(`/bank-cards/${id}/update`);
    }
  }

  return (
    <BankCardsUIProvider uIEvents={bankCardsUIEvents}>
      <Route path="/bank-cards/:id/update">
        {({history, match}) => {
          return <UpdateBankCardsModal
            bankCardDataStr={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/bank-cards");
            }}
          />
        }}
      </Route>
      <BankCardsList/>
    </BankCardsUIProvider>
  );
}

BankCardsPage.propTypes = {};
BankCardsPage.defaultProps = {};

export default BankCardsPage;
