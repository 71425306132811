import React, {useEffect} from 'react';
import {Button, Modal, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {Controller, useForm} from "react-hook-form";
import {fetchBankCards, updateBankCard} from "../../../../redux/bank-cards/bankCardsActions";
import {useBankCardsUIContext} from "../BankCardsUIContext";

function UpdateBankCardsModal({onHide, show, bankCardDataStr}) {
  const bankCardInfo = bankCardDataStr ? JSON.parse(window.atob(bankCardDataStr)) : {};

  console.log({
    bankCardInfo
  })

  const dispatch = useDispatch()
  const {queryParams} = useBankCardsUIContext() || {};
  const {handleSubmit, reset, errors, control} = useForm();

  const {actionsLoading: loading} = useSelector(
    (state) => ({
      actionsLoading: state.bankCards.actionsLoading,
    }),
    shallowEqual
  );

  const onSubmit = (formValues) => {
    dispatch(updateBankCard({...formValues, companyId: bankCardInfo.companyId})).then(rs => {
      if (rs) {
        reset({})
        dispatch(fetchBankCards(queryParams))
        onHide()
      }
    })
  }
  useEffect(() => {
    reset({...bankCardInfo})
  }, [bankCardDataStr])
  // companyId, bankAccountNumber
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="form form-label-right">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg"> {bankCardInfo.bankAccountNumber ? 'Update' : 'Add'} Bank Card</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <div className="form-group row">
            <div className="col-lg-12">
              <small className="form-text text-muted">
                <b>Bank Account Number</b>
              </small>
              <Controller
                control={control}
                name="bankAccountNumber"
                defaultValue={""}
                rules={{validate: (value) => {
                  if(value && value.length < 16) {
                    return false
                  }
                  const numberStr = value.split("*").join("").split("-").join("")
                  if(numberStr.length < 16) {
                    return false
                  }

                  if(value !== bankCardInfo.bankAccountNumber && isNaN(numberStr)) {
                    return false
                  }
                  }}}
                as={<input type={'text'} placeholder="Bank Account Number" className="form-control"/>}
              />
              {
                errors.bankAccountNumber && <div className={"invalid-feedback"} style={{display: 'block'}}>
                  Only accept 16 numbers
                </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            loading && <Button variant="primary" className={"mr-3"} disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          }
          {
            !loading && <React.Fragment>
              <Button variant="secondary" onClick={onHide}>No</Button>
              <Button variant="primary" type={'submit'}>Yes</Button>
            </React.Fragment>
          }
        </Modal.Footer>
      </form>
    </Modal>
  );
}

UpdateBankCardsModal.propTypes = {};
UpdateBankCardsModal.defaultProps = {};

export default UpdateBankCardsModal;
