import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function ActionsColumnFormatter({onEdit}) {
  return (
    <>
      {
        <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip>
              Edit
            </Tooltip>
          }
        >
          <a
            title="Edit"
            className="btn btn-icon btn-success btn-sm"
            onClick={onEdit}
          >
            <i className="fa fa-edit"/>
          </a>
        </OverlayTrigger>
      }
    </>
  );
}

ActionsColumnFormatter.propTypes = {};
ActionsColumnFormatter.defaultProps = {};

export default ActionsColumnFormatter;
