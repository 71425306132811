import React from 'react';
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import YesNoLabel from "../../../components/YesNoLabel";

function GeneratedLicenseList({licenseItems = []}) {
  return (
    <Card>
      <Card.Header>
        VPOST API License List
      </Card.Header>
      <Card.Body>
        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>#</th>
            <th>Access Token</th>
            <th>Active</th>
            <th>Terminal</th>
          </tr>
          </thead>
          <tbody>
          {
            (licenseItems || []).map((lItem, index) => {
              return  <tr key={lItem.nid}>
                <td>{index + 1}</td>
                <td>{lItem.serial}</td>
                <td><YesNoLabel value={lItem.bactive}/></td>
                <td>{lItem.terminal}</td>
              </tr>
            })
          }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

GeneratedLicenseList.propTypes = {};
GeneratedLicenseList.defaultProps = {};

export default GeneratedLicenseList;
