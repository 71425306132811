import * as licenseApi from "../../../../../apis/licenseApi";
import {licenseReseller3rdSlice, callTypes} from "./licensesReseller3rdSlice";

import {toast} from "react-toastify";

const {actions} = licenseReseller3rdSlice;

export const fetchReseller3rdLicenses = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return licenseApi
    .getAll3rdResellers(queryParams)
    .then(licenseReseller3rdItems => {
      dispatch(actions.licenseReseller3rdsFetched({ totalCount: licenseReseller3rdItems.length, licenseReseller3rdItems }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Licenses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createReseller3rdLicense = formData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return licenseApi
    .create3rdReseller(formData)
    .then(response => {
      dispatch(actions.licenseReseller3rdCreated(response));
      toast.success("Create Reseller3rd License Successfully!");
      return response;
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false
    });
};

export const getReseller3rdLicense = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return licenseApi
    .get3rdReseller(id)
    .then(response => {
      dispatch(actions.licenseReseller3rdFetched(response));
      return response;
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false
    });
};


export const updateReseller3rdLicense = (id, formData) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return licenseApi
    .update3rdReseller(id, formData)
    .then(response => {
      dispatch(actions.licenseReseller3rdUpdated(response));
      toast.success("Update Reseller3rd License Successfully!");
      return response;
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false
    });
};


export const resetReseller3rdLicenseState = () => dispatch => {
  dispatch(actions.resetState());
};

export const setEditingRes3rdItemId = (id) => dispatch => {
  dispatch(actions.setEditingRes3rdItemId(id));
};
