import React, {useEffect, useMemo} from 'react';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider,} from "react-bootstrap-table2-paginator";

import {getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage,} from "../../../../_metronic/_helpers";

import {Pagination} from "../../../../_metronic/_partials/controls";

import {useBankCardsUIContext} from "../BankCardsUIContext";
import {useDispatch} from "react-redux";
import * as uiHelpers from "../BankCardsUIHelpers";
import * as actions from "../../../../redux/bank-cards/bankCardsActions";
import useBankCardsSelector from "../hooks/useBankCardsSelector";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openUpdateBankCardModal,
  }
) {
  const actionLabel = row.sbankCardNumber ? 'Edit' : 'Add';

  return (
    <>
      {
        <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip>
              {actionLabel}
            </Tooltip>
          }
        >
          <button
            title={actionLabel}
            className={`btn btn-${actionLabel === 'Edit' ? 'warning' : 'success'} btn-sm`}
            onClick={() => openUpdateBankCardModal(window.btoa(JSON.stringify({
              bankAccountNumber: row.sbankCardNumber,
              companyId: row.nid
            })))}
          >
            {
              actionLabel
            }
          </button>
        </OverlayTrigger>
      }

    </>
  );
}

function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function BankCardsResults() {
  const bankCardsUIContext = useBankCardsUIContext();
  const bankCardsUIProps = useMemo(() => {
    return {
      queryParams: bankCardsUIContext.queryParams,
      activeTabKey: bankCardsUIContext.activeTabKey,
      setQueryParams: bankCardsUIContext.setQueryParams,
      openUpdateBankCardModal: bankCardsUIContext.openUpdateBankCardModal
    };
  }, [bankCardsUIContext]);

  const {totalCount, entities, listLoading} = useBankCardsSelector() || {};
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchBankCards({
      ...bankCardsUIProps.queryParams,
      filter: {
        ...bankCardsUIProps.queryParams.filter,
      }
    }))
  }, [bankCardsUIProps.queryParams.filter.searchText, dispatch]);

  const columns = [
    {
      text: "Company Name",
      dataField: "sname",
    }, {
      text: "Bank Card",
      dataField: "sbankCardNumber",
    },
    {
      text: "action",
      dataField: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openUpdateBankCardModal: bankCardsUIProps.openUpdateBankCardModal,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: bankCardsUIProps.queryParams.pageSize,
    page: bankCardsUIProps.queryParams.pageNumber,
  };
  const entitiesPaginated = paginate([...(entities || [])], paginationOptions.sizePerPage, paginationOptions.page);
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({paginationProps, paginationTableProps}) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="serial"
                data={entities === null ? [] : entitiesPaginated}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  bankCardsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities}/>
                <NoRecordsFoundMessage entities={entities}/>
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

BankCardsResults.propTypes = {};
BankCardsResults.defaultProps = {};

export default BankCardsResults;
