import {useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";

export function useReseller3rdSystemStateSelector() {
  const {reseller3rdSystemState} = useSelector(
    (state) => ({reseller3rdSystemState: state.reseller3rdLicenses}),
    shallowEqual
  )
  return reseller3rdSystemState
}

export function useOpenRes3rdEditIdSelector() {
  const {licenseReseller3rdEditId} = useReseller3rdSystemStateSelector() || {};
  return licenseReseller3rdEditId
}

export function useRes3rdEditItemSelector() {
  const {licenseReseller3rdItem} = useReseller3rdSystemStateSelector() || {};
  return licenseReseller3rdItem
}

export function useRes3rdActionsLoadingSelector() {
  const {actionsLoading} = useReseller3rdSystemStateSelector() || {};
  return actionsLoading
}
