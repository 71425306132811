import React from 'react';
import {Button, Spinner} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import * as uiHelpers from "../../LicensesUIHelpers";
import {NoRecordsFoundMessage, PleaseWaitMessage} from "../../../../../_metronic/_helpers";
import {useReseller3rdLicenses} from "../hooks/useReseller3rdLicenses";

function Reseller3rdSystemList() {
  const {entities, loading, tableCols} = useReseller3rdLicenses();
  return (
    <div>
      {
        loading && <Button variant="primary" className={"mr-3"} disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button>
      }
      {
        !loading && <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          remote
          keyField="serial"
          data={entities}
          columns={tableCols}
          defaultSorted={uiHelpers.defaultSorted}
        >
          <PleaseWaitMessage entities={entities}/>
          <NoRecordsFoundMessage entities={entities}/>
        </BootstrapTable>
      }

    </div>
  );
}

Reseller3rdSystemList.propTypes = {};
Reseller3rdSystemList.defaultProps = {};

export default Reseller3rdSystemList;
