import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";

export function TerminalInfoModalHeader() {
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.licenses.actionsLoading,
    }),
    shallowEqual
  );
  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Terminal Information</Modal.Title>
      </Modal.Header>
    </>
  );
}
