import React, {useEffect, useMemo} from 'react';
import {isEqual} from "lodash";
import {useForm} from 'react-hook-form';
import {Button, Spinner} from 'react-bootstrap'


import {useBankCardsUIContext} from "../BankCardsUIContext";
import useBankCardsSelector from "../hooks/useBankCardsSelector";

const prepareFilter = (queryParams, values) => {
  const newQueryParams = {...queryParams};
  newQueryParams.filter = {
    ...values
  };
  return newQueryParams;
};

function BankCardsFilter() {
  // Customers UI Context
  const bankCardsUIContext = useBankCardsUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: bankCardsUIContext.queryParams,
      setQueryParams: bankCardsUIContext.setQueryParams,
    };
  }, [bankCardsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter({
      ...customersUIProps.queryParams
    }, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const {register, handleSubmit, reset} = useForm();

  const handleResetSearch = () => {
    reset();
    handleSubmit()
  }
  const {listLoading, totalCount} = useBankCardsSelector() || {};
  return (
    <form onSubmit={handleSubmit(applyFilter)} className="form form-label-right">
      <div className="form-group row">
        <div className="col-lg-6">
          <input
            type="text"
            ref={register}
            className="form-control"
            name="searchText"
            placeholder="Search"
          />
          <small className="form-text text-muted">
            <b>Search</b> by company name
          </small>
        </div>

        <div className="col-lg-3">
          {
            listLoading && <Button variant="success" className={"mr-3"} disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Searching...
            </Button>
          }
          {
            !listLoading && <button
              className="btn btn-success mr-3"
            >
              Search
            </button>
          }
          <button
            onClick={handleResetSearch}
            className="btn btn-warning"
          >
            Reset
          </button>
        </div>
        <div className="col-lg-3 fs-3 font-weight-bold">
          <h5>Total: {totalCount}</h5>
        </div>
      </div>
    </form>
  );
}

BankCardsFilter.propTypes = {};
BankCardsFilter.defaultProps = {};

export default BankCardsFilter;
