import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/es/utils/shallowEqual";
import {fetchLicenses, resetLicense} from "../../../../redux/licenses/licensesActions";
import ConfirmModal from "../../../components/ConfirmModal";
import {useLicensesUIContext} from "../LicensesUIContext";

function ResetLicenseModal({onHide, show, licenseId}) {
  const licenseInfo = licenseId ? JSON.parse(window.atob(licenseId)) : {};
  console.log({
    licenseInfo
  })

  const dispatch = useDispatch()
  const liContext = useLicensesUIContext();
  const liUIProps = React.useMemo(() => {
    return {
      queryParams: liContext.queryParams,
    };
  }, [liContext]);
  const {actionsLoading, error} = useSelector(
    (state) => ({
      actionsLoading: state.licenses.actionsLoading,
      error: state.licenses.error
    }),
    shallowEqual
  );
  const handleOk = () => {
    if(!licenseId) {
      return;
    }
    dispatch(resetLicense(licenseInfo.nid)).then((rs) => {
      if(rs) {
        dispatch(fetchLicenses(liUIProps.queryParams))
        onHide();
      }
    })
  }
  return (
    <ConfirmModal
      error={error}
      title={<span>Reset License</span>}
      show={show}
      loading={actionsLoading}
      content={<span>Do you want to reset <strong>{licenseInfo.terminal}</strong>?</span>}
      onOk={handleOk}
      onHide={onHide}
    />
  );
}

ResetLicenseModal.propTypes = {};
ResetLicenseModal.defaultProps = {};

export default ResetLicenseModal;
