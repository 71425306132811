import React from 'react';
import {Card, CardBody, CardHeader} from "../../../../_metronic/_partials/controls";

import BankCardsFilter from "./Filter";
import BankCardsResults from "./Results";

function BankCardsList() {
  return (
    <Card>
      <CardHeader title="Bank Card List">
      </CardHeader>
      <CardBody>
        <BankCardsFilter/>
        <BankCardsResults />
      </CardBody>
    </Card>
  );
}

BankCardsList.propTypes = {};
BankCardsList.defaultProps = {};

export default BankCardsList;
