import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./LicensesUIHelpers";
import {LICENSE_TYPE} from "../../constants";

const LicensesUIContext = createContext();

export function useLicensesUIContext() {
  return useContext(LicensesUIContext);
}

export const LicensesUIConsumer = LicensesUIContext.Consumer;

export function LicensesUIProvider({uIEvents = {}, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [activeTabKey, setActiveTabKey] = useState(LICENSE_TYPE.APP);

  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initGenerateLicensesValues =  {
    total: 1,
    resselerId: ""
  }

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initGenerateLicensesValues,
    openTerminalInfoModal: uIEvents.openTerminalInfoModal,
    openResetLicenseModal: uIEvents.openResetLicenseModal,
    openActivateLicenseModal: uIEvents.openActivateLicenseModal,
    openGenerateLicenseModal: uIEvents.openGenerateLicenseModal,
    setActiveTabKey,
    activeTabKey,
  };

  return <LicensesUIContext.Provider value={value}>{children}</LicensesUIContext.Provider>;
}
