import React from 'react';
import {Route} from "react-router-dom";
import PropTypes from 'prop-types';
import LicensesList from "./List";
import {useSubheader} from "../../../_metronic/layout";
import {LicensesUIProvider} from "./LicensesUIContext";
import TerminalInfoModal from "./TerminalInfoModal";
import ResetLicenseModal from "./ResetLicenseModal";
import ActiveLicenseModal from "./ActiveLicenseModal";
import GenerateLicenseModal from "./GenerateLicenseModal";

function LicensesPage({history}) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Licenses");
  const licensesUIEvents = {
    openTerminalInfoModal: (terminalStockId) => {
      history.push(`/licenses/terminal-info/${terminalStockId}`);
    },
    openResetLicenseModal: (liId) => {
      history.push(`/licenses/${liId}/reset`);
    },
    openActivateLicenseModal: (liId) => {
      history.push(`/licenses/${liId}/activate`);
    },
    openGenerateLicenseModal: () => {
      history.push(`/licenses/generate`);
    }
  }

  return (
    <LicensesUIProvider uIEvents={licensesUIEvents}>
      <Route path="/licenses/terminal-info/:terminalStockId">
        {({history, match}) => {
          return <TerminalInfoModal
            show={match != null}
            terminalStockId={match && match.params.terminalStockId}
            onHide={() => {
              history.push("/licenses");
            }}
          />
        }}
      </Route>
      <Route path="/licenses/:liId/reset">
        {({history, match}) => {
          return <ResetLicenseModal
            show={match != null}
            licenseId={match && match.params.liId}
            onHide={() => {
              history.push("/licenses");
            }}
          />
        }}
      </Route>
      <Route path="/licenses/:liId/activate">
        {({history, match}) => {
          return <ActiveLicenseModal
            show={match != null}
            licenseId={match && match.params.liId}
            onHide={() => {
              history.push("/licenses");
            }}
          />
        }}
      </Route>

      <Route path="/licenses/generate">
        {({history, match}) => {
          return <GenerateLicenseModal
            show={match != null}
            onHide={() => {
              history.push("/licenses");
            }}
          />
        }}
      </Route>
      <LicensesList/>
    </LicensesUIProvider>
  );
}

LicensesPage.propTypes = {};
LicensesPage.defaultProps = {};

export default LicensesPage;
